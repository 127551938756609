html {
  padding-bottom: 60px;
}
#EnvironmentSection {
  position: fixed;
  width: 100%;
  background: #EEE;
  bottom: 0;
  z-index: 100;
  border-top: 1px solid #AAA;
  font-size: 8pt;
  color: black;
  box-shadow: inset 0px 7px 45px -2px rgba(0, 0, 0, 0.1);
}
#EnvironmentSectionInner {
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  overflow: hidden;
}
#EnvironmentSection span {
  border-right: 1px solid #ccc;
  padding-right: 10px;
  margin-right: 10px;
  display: block;
  float: left;
}
#EnvironmentSection ul {
  list-style-type: none;
  float: left;
  padding: 0 10px 0 0;
  margin-right: 10px;
  border-right: 1px dotted #AAA;
}
#EnvironmentSection ul:last-child {
  border: 0;
}
#EnvironmentSection .buttons li a {
  display: block;
  /*float:left;*/
  margin-bottom: 3px;
  padding: 2px 5px;
  border: 1px solid #BBB;
  border-radius: 2px;
  color: #000;
  text-decoration: none;
  background: #FFF;
}
#EnvironmentSection .buttons li a:hover {
  text-decoration: underline;
}
#EnvironmentSectionClose {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #FFF;
  width: 14px;
  height: 14px;
  border: 1px solid #BBB;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  line-height: 13px;
}
#EnvironmentSectionClose:hover {
  text-decoration: underline;
}
.Test body {
  background-color: #eee;
}
.environment-framework-version {
  background-color: #00a4ef;
  padding: 1rem;
  color: white;
}
.test-page .environment-framework-version-name {
  float: right;
  display: inline-block;
}
.test-page .test-links .btn {
  margin: 0.25rem 0.125rem;
}
@media (max-width: 767px) {
  html {
    padding-bottom: 0;
  }
  #EnvironmentSection {
    display: none;
  }
  .test-page .environment-framework-version-name {
    float: none;
  }
}

